/* Styles for AppBar component */
.AppLogo {
    height: 45px;
    width: 115px;
}
.nvabar {
    min-height: auto;
    padding: 4px 0px 0px 0px;
    box-shadow: none;
}
.app-bar {
    height: 64px;
}

.nav-link a {
    text-decoration: none !important;
    color: var(--coal) !important;
    font-family: var(--theme-font);
    padding: 1.7rem 0.4rem;
    border-bottom: 3px solid transparent;
    border-radius: 0px;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 18px !important;
    text-transform: capitalize !important;
    list-style: none;
}

.nav-link a.active {
    border-bottom: 3px solid var(--primary);
    border-radius: 2px 2px 0 0;
}
@media (max-width: 600px) {
    .nav-link {
        padding: 0.8rem 0.9rem !important;
    }
}
.mobile-menu-width {
    left: 0px !important;
    top: 3.02rem !important;
    width: 320px !important;
    border-radius: 0px !important;
    padding: 0px auto !important;
}
