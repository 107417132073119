.button-primary {
    padding: 0.33rem 1.5rem;
    color: var(--bg-white);
    background-color: var(--primary);
    height: 35px;
    border: 0px;
    font-family: var(--theme-font);
    font-size: 14px;
    letter-spacing: 0;
    border-radius: 4px;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    line-height: 14px;
    text-align: center;
    cursor: pointer;
}
.button-transparent {
    padding: 0.33rem 1.5rem;
    color: black;
    background-color: transparent;
    height: 40px;
    width: auto;

    font-family: var(--theme-font);
    font-size: 14px;

    letter-spacing: 0;
    border-radius: 4px;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    line-height: 14px;
    text-align: center;
}
.button-transparent a {
    color: var(--coal);
}
.edit-button {
    height: 30px;
    width: 125px;
    background-color: #e6e8ea;
    border-radius: 2px;
    color: #1a2733;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    outline: none !important;
    border-radius: 0px;
}

.edit-button a {
    text-decoration: none;
    color: var(--coal);
}
.complete-button {
    text-transform: initial;
    width: 125px;
    height: 30px;
    color: var(--coal);
    padding: 0.2rem 1rem;
    background-color: #d8d8d8;
    border: 0.5px solid #4caf4f;
    border-radius: 2px;
    font-weight: bold;
}
.add-offer-btn {
    background-color: #e6e8ea;
    border-radius: 2px;
    color: #1a2733;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
}
.sort-icon {
    font-size: 14px;
    background-color: #e6e8ea;
    color: #1a2733;
    cursor: pointer;
    padding: 0.1rem;
    margin-top: 0.2rem;
    border-radius: 100%;
    &:hover {
        transform: rotate(180deg);
    }
}

.MuiSvgIcon-root {
    cursor: pointer;
}
