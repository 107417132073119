/* LoginPage.css */
.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
}

.loginBox {
  padding: 30px;
  border-radius: 2px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.textField {
  width: 100%;
  margin-bottom: 16px;
}
