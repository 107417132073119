/* Global styles for the app */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

@import './components/';
@import './views/';

:root {
    --theme-font: 'Lato', sans-serif;
    --body-bg: #e6e8ea;
    --primary: #0099ff;
    --coal: #1a2733;
    --bg-white: #ffffff;
    --modal-hr: #d8d8d8;
    --modal-backdrop: #88c2f5;
    --bg-black: #000000;
}

body {
    margin: 0;
    font-family: var(--theme-font);
    background-color: var(--body-bg);
}


.w-100 {
    width: 100%;
}

// -----------------global Background Colors-----------------//

.bg-white {
    background-color: var(--bg-white);
}
.bg-primary {
    background-color: var(--primary);
}

p {
    color: var(--coal);
    font-family: Lato;
    margin: 0;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}


// ------------CheckBox Style-------------- //
.css-i4bv87-MuiSvgIcon-root {
    width: 20px;
    height: 20px;
}

.MuiFormLabel-root {
    color: var(--coal) !important;
    font-family: 'Lato';
    font-size: 14px;
    letter-spacing: 0;

    line-height: 18px;
}

// -----------------Page Title------------------ //
.pagetile-p {
    width: 400px;
    opacity: 0.5;
    color: #1a2733;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-align: right;
}




// Grid Control
@media (min-width: 1200px) {
    .css-1oqqzyl-MuiContainer-root {
        max-width: 1260px;
    }
}
@media (max-width: 320px) {
}
